<template>
  <v-container class="fill-height background-blue pa-0" fluid>
    <div>
      <div class="header1"></div>
      <div class="header2"></div>
    </div>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6" lg="4" xl="4" xs="12" class="text-center">
        <v-avatar height="177" width="177">
          <v-img :src="require('@/assets/images/logoHome.svg')" contain />
        </v-avatar>
        <p class="display-3 white--text">LAYANAN</p>
        <p class="display-2 white--text">KEPEGAWAIAN</p>
      </v-col>
    </v-row>
    <div class="footer">
      <v-row align="center" justify="center">
        <v-col
          cols="12"
          sm="8"
          md="6"
          lg="4"
          xl="4"
          xs="12"
          class="text-center"
        >
          <v-list-item>
            <v-list-item-avatar>
              <v-icon color="white">pin_drop</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-description class="subtitle-1 white--text"
                >Kompleks Kemdikbud Gedung E Lantai 5 Jalan Jenderal Sudirman,
                Senayan, Jakarta 10270</v-list-item-description
              >
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col
          cols="12"
          sm="8"
          md="6"
          lg="4"
          xl="4"
          xs="12"
          class="text-center"
        >
          <v-list-item>
            <v-list-item-avatar>
              <v-icon color="white">mail</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-description class="subtitle-1 white--text"
                >kepegawaian.pauddasmen@kemdikbud.go.id</v-list-item-description
              >
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col
          cols="12"
          sm="8"
          md="6"
          lg="4"
          xl="4"
          xs="12"
          class="text-center"
        >
          <v-list-item>
            <v-list-item-avatar>
              <v-icon color="white">call</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-description class="subtitle-1 white--text"
                >(021) 5725061; Faks: (021) 5725613</v-list-item-description
              >
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <p class="subtitle-1 white--text text-center mb-1 mt-2">
        Copyright © 2021 - Direktorat Jenderal Pendidikan Anak Usia Dini,
        Pendidikan Dasar, dan Pendidikan Menengah
      </p>
      <p class="subtitle-1 white--text text-center">Kebijakan Privasi</p>
    </div>
  </v-container>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  setup() {}
});
</script>

<style scoped>
.background-blue {
  background-color: #1c3e69;
  height: "100%";
}
.header1 {
  width: 100%;
  height: 77px;
  padding: 2px 2px 2px 2px;
  background-color: #06324b;
  box-sizing: border-box;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.35);
  position: absolute;
  z-index: 2;
  top: 0;
}
.header2 {
  width: 100%;
  height: 85px;
  padding: 2px 2px 2px 2px;
  background-color: #fb8500;
  box-sizing: border-box;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.35);
  position: absolute;
  z-index: 1;
  top: 0;
}
.logo {
  background-color: transparent;
}
.footer {
  width: 100%;
  height: 196px;
  padding: 2px 2px 2px 2px;
  background-color: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
}
</style>
